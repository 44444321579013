import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Utils                from "Dashboard/Utils/Utils";
import Conversations        from "Utils/Conversations";

// Components
import ChannelIcon          from "Components/Utils/Common/ChannelIcon";
import ColorItem            from "Components/Utils/Common/ColorItem";

// Dashboard
import Button               from "Dashboard/Components/Form/Button";
import CircularLoader       from "Dashboard/Components/Loader/CircularLoader";
import NoneAvailable        from "Dashboard/Components/Common/NoneAvailable";
import Icon                 from "Dashboard/Components/Common/Icon";
import Html                 from "Dashboard/Components/Common/Html";



// Styles
const Container = Styled.ul`
    flex-grow: 2;
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0;
    border: var(--border-width) solid var(--border-color-light);
    border-radius: var(--border-radius);
    overflow: auto;
`;

const None = Styled(NoneAvailable)`
    margin: 8px;
    text-align: center;
`;

const Item = Styled.li.attrs(({ isSelected }) => ({ isSelected }))`
    position: relative;
    display: grid;
    padding: 8px;
    grid-template-areas:
        "icon contact time"
        "icon message message"
        "icon tags tags";
    grid-template-columns: 36px 4fr 1fr;
    gap: 8px;
    border-bottom: 1px solid var(--border-color-light);
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
        background-color: var(--lighter-gray);
    }
    &:hover .btn {
        display: flex;
    }
    ${(props) => props.isSelected && "background-color: var(--lightest-gray);"}
`;

const ItemChannel = Styled.div`
    grid-area: icon;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ItemContact = Styled.h3`
    grid-area: contact;
    margin: 0px;
    font-size: 14px;
    color: var(--title-color);
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ItemTime = Styled.p.attrs(({ isUnread }) => ({ isUnread }))`
    grid-area: time;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    margin: 0;
    color: var(--lighter-color);
    font-size: 12px;
    text-align: right;
    white-space: nowrap;

    .icon {
        font-size: 14px;
        color: var(--primary-color);
    }

    ${(props) => props.isUnread && `
        color: var(--notify-color);
        font-weight: bold;

        .icon {
            color: var(--notify-color);
        }
    `}
`;

const ItemMessage = Styled(Html)`
    grid-area: message;
    margin: 0;
    line-height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const ItemAssigns = Styled.div`
    grid-area: tags;
    display: flex;
    flex-wrap: wrap;
    gap: 4px 8px;
`;

const ItemButton = Styled(Button)`
    display: none;
    position: absolute;
    bottom: 8px;
    right: 8px;
`;



/**
 * The List Content
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ListContent(props) {
    const { fetch } = props;
    const { isClientUser } = Store.useState("auth");

    const navigate = Conversations.useGoto();
    const { conversationTab, conversationID } = Navigate.useParams();

    const { isAnyAdmin } = Store.useState("auth");
    const { hasHospitality } = Store.useState("permission");
    const { list, total, sort, showSearch, searching, searchTab, search } = Store.useState("conversation");
    const { acceptConversation } = Store.useAction("conversation");


    // The References
    const containerRef = React.useRef(null);

    // The Current State
    const [ isUpdating, setUpdating ] = React.useState(false);


    // The Data
    const items      = showSearch ? search[`${searchTab}List`] : list;
    const showNone   = !searching && !items.length;
    const showList   = !searching && !!items.length;
    const showUnread = !isAnyAdmin && sort.filter !== "Resolved";


    // Handles the Scroll
    const handleScroll = async () => {
        const node = containerRef.current;
        if (!node) {
            return;
        }
        if (!isUpdating && sort.amount < total &&
            node.scrollTop >= (node.scrollHeight - node.offsetHeight)
        ) {
            setUpdating(true);
            const amount = sort.amount + sort.perPage;
            await fetch({ ...sort, amount });
            setUpdating(false);
        }
    };

    // Handles the Item
    const handleItem = (elemID) => {
        const elemNumber = Utils.getValue(items, "id", elemID, "number");
        if (elemNumber !== conversationID) {
            navigate(conversationTab, elemNumber);
        }
    };

    // Handles the Accept Action
    const handleAccept = async (elemID) => {
        const elemNumber = Utils.getValue(items, "id", elemID, "number");
        await acceptConversation(elemID);
        navigate("PROGRESS", elemNumber);
    };



    // Do the Render
    return <Container onScroll={handleScroll} ref={containerRef}>
        {searching && <CircularLoader message="GENERAL_SEARCHING" withSpacing />}
        {showNone && <None message="CONVERSATIONS_NONE_AVAILABLE" />}
        {showList && items.map((item) => <Item
            key={item.id}
            isSelected={item.number === conversationID}
            onClick={() => handleItem(item.id)}
        >
            <ItemChannel>
                <ChannelIcon
                    icon={item.providerIcon}
                    color={item.providerColor}
                    size={32}
                />
            </ItemChannel>

            <ItemContact>
                {item.contactName || NLS.get("CONTACTS_NO_NAME")}
            </ItemContact>

            <ItemTime isUnread={showUnread && item.isUnread}>
                <span className={`text-${item.expiredColor}`}>
                    {item.lastReplyText}
                </span>
                <Icon
                    isHidden={!showUnread || !item.isUnread}
                    icon="circle"
                />
                <Icon
                    isHidden={!hasHospitality || item.booking === 0}
                    icon="star-outline"
                />
                <Icon
                    isHidden={!hasHospitality || item.checkedin === 0}
                    icon="star"
                />
            </ItemTime>

            <ItemMessage formatText>
                {item.lastMessageText}
            </ItemMessage>

            <ItemAssigns>
                {!!item.userID && <ColorItem
                    color={item.userColor}
                    message={item.userName}
                />}
                {!!item.teamID && <ColorItem
                    color={item.teamColor}
                    message={item.teamName}
                />}
                {!!item.tongueID && <ColorItem
                    color={item.tongueColor}
                    message={item.languageName}
                />}
                {item.tagList.map((elem) => <ColorItem
                    key={elem.tagID}
                    color={elem.color}
                    message={elem.name}
                />)}
            </ItemAssigns>

            <ItemButton
                variant="primary"
                icon="progress"
                message="GENERAL_ACCEPT"
                onClick={() => handleAccept(item.id)}
                isHidden={!isClientUser || !item.isQueue}
                isSmall
            />
        </Item>)}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ListContent.propTypes = {
    fetch : PropTypes.func.isRequired,
};

export default ListContent;
